/* Global Variables */
:root {
  /* green theme */
  --primary: #379237;
  --secondary: #82CD47;
  --grey: #baceba;
  --black: #121212;
  --white: #fff;
}

/* Global Styles */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto -60px;
  padding: 40px 0;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Body Styles */

body {
  background: var(--secondary);
}

/* Title Styles */

.title {
  width: 100%;
  padding: 26px 0;
  color: var(--white);
  text-align: center;
  background: var(--primary);
}
.languages {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 20px;
  cursor: pointer;
}
.title h1{
  margin-bottom: 10px;
}
.book-name{
  margin-top: 10px;
}
/* Options Styles */

.options {
  width: 100%;
  padding: 20px 0;
  margin: 10px 0 0 0;
}

.options .wrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.options .wrapper .optionsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options .wrapper .optionsContainer p {
  margin-bottom: 10px;
  color: var(--primary);
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 1.2px;
}

.options .wrapper .optionsContainer input,
.options .wrapper .optionsContainer select {
  background: var(--primary);
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
  border: none;
  outline: none;
  padding: 10px 20px;
}

.options .wrapper .optionsContainer input {
  width: 90px;
}

.options .wrapper .optionsContainer select {
  appearance: none;
  width: 140px;
}

.options .wrapper .copy button,
.btn-copy {
  border: none;
  outline: none;
  width: 164px;
  background: var(--primary);
  color: var(--white);
  padding: 13px 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  border-radius: 4px;
}

.btn-copy {
  width: 100%;
  margin-bottom: 20px;
}

/* Output Styles */

.output {
  width: 100%;
  padding: 40px;
  margin-bottom: 15px;
  background: var(--primary);
  border-radius: 4px;
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  color: var(--white);
}

.output p {
  line-height: 1.8;
}

.paragraph-section{
  display: flex;
}

.btn-restart-label{
  display: none;
}

footer {
  background-color: var(--primary);
  color: var(--white);
  padding: 20px;
  text-align: center;
  height: 60px;
  margin-top: auto;
}

footer a{
  text-decoration: none;
}
footer a, footer a:active {
  font-weight: bold;
  color: var(--secondary);
}


/* RELOAD BUTTON */
.buttons {
  width: 30px;
  padding: 10px 20px;
  text-align: center;
}

button {
  position: relative;
  width: 39px;
  height: 39px;
  margin: 0 ;
  padding: 0;
  font-size: 12px;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: transform 0.4s ease-in-out;
  align-items: center;
}

button:active {
top: 1px;
box-shadow: none;
}

.btn-restart, .btn-copy {
  background: var(--primary);
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}
.btn-restart .icon {
  width: 18px;
  height: 18px;
  transition: all 0.4s ease-in-out;
}
.btn-restart:hover .icon {
  transform: rotate(360deg) scale(1.2);
}

.btn-restart {
  border-left: 2px solid var(--secondary);
}

.disabled-text {
  color: var(--grey) !important;
}

#select-html:disabled {
  opacity: 40%;
}


/* Media Queries */

@media (max-width: 640px) {
  .title {
    padding: 26px;
  }
  .options .wrapper {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .optionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .options .wrapper .optionsContainer .paragraphs,
  .options .wrapper .optionsContainer .include,
  .options .wrapper .optionsContainer .tags {
    width: 100%;
    margin: 10px 0;
  }
  .options .wrapper .optionsContainer .paragraphs p,
  .options .wrapper .optionsContainer .include p,
  .options .wrapper .optionsContainer .tags p {
    font-size: 14px;
  }
  .options .wrapper .optionsContainer input,
  .options .wrapper .optionsContainer select {
    width: 100%;
  }
  .options .wrapper .copy {
    width: 100%;
  }
  .options .wrapper .copy button {
    margin: 10px 0;
    width: 100%;
    padding: 24px 0;
  }
}

@media (min-width: 641px) and (max-width: 940px) {
  .options .wrapper {
    flex-direction: column;
  }
  .options .wrapper .optionsContainer {
    flex-direction: column;
    max-width: 100%;
    justify-content: space-between;
  }
  .options .wrapper .optionsContainer p{
    margin-top: 10px;
  }
  .options .wrapper .optionsContainer .paragraphs,
  .options .wrapper .optionsContainer .include,
  .options .wrapper .optionsContainer .tags {
    width: 100%;
  }
  .options .wrapper .copy {
    width: 100%;
  }
  .options .wrapper .copy button {
    margin: 10px 0;
  }
  .options .wrapper .optionsContainer input,
  .options .wrapper .optionsContainer select {
    width: 100%;
  }
  .options .wrapper .copy button {
    margin: 10px 0;
    width: 100%;
    padding: 24px 0;
  }
}

@media (max-width: 1240px) {
  .container {
    max-width: 90%;
  }
  .options .wrapper .optionsContainer p{
    margin-top: 0;
  }
  .copy{
    display: flex;
    flex-direction: row;
  }

}